<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Facility Siting and Design Experience"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Facility Siting Project for Static Launch Vehicle Operations"
        />
        <Paragraph
          ><ul>
            <li>
              Static test stands were reviewed and analyzed for facility siting
              issues, potential safety hazards during storage (compatibility)
            </li>
            <li>
              Remote control stations evaluated for compliance with required
              protection levels
            </li>
            <li>
              Run tanks evaluated for key safety features that can reduce risk
              in the event of a mishap
            </li>
            <li>
              Quantity-Distance (QD) calculations performed based on equivalent
              net explosive weight (NEW) as derived from tank and vessel
              capacities provided by customer
              <ul>
                <li>
                  Determination of accurate density values for cryogenic liquids
                  and other fuels, oxidizers
                </li>
                <li>
                  Fragment throw distance determination based on empirical
                  testing and accident data
                </li>
                <li>
                  Developed outline of QD issues of concern with proposed
                  action(s) to address each issue
                </li>
              </ul>
            </li>
            <li>
              Integrated fire protection, fire detection methods and withdrawal
              distances into written report due to high fire hazards and highly
              toxic materials at the site
            </li>
            <li>
              Site maps for each area developed to include potential explosion
              sites (PESs), exposed sites (ESs) and relevant QD arcs
            </li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "D3 Experience",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has extensive experience in reviewing test stands and remote-control stations, evaluating run tanks, performing quantity-distance (QD) calculations based on net explosive weight (NEW), and identifying potential explosion sites (PESs) and exposed sites (ESs)."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
